import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';

export const wrapper = css`
  margin-top: calc(var(--unit) * 15);
  grid-column: 1 / span 12;

  @media ${breakpoints.medium} {
    display: flex;
  }

  @media ${breakpoints.large} {
    grid-column: 5 / span 6;
  }
`;

export const icon = (error?: boolean) => css`
  width: calc(var(--unit) * 12);
  height: calc(var(--unit) * 12);
  border-radius: 50%;
  border: ${error ? 'none' : '1px solid hsl(var(--color-text-1))'};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calc(var(--unit) * 5);
`;

export const title = (error?: boolean) => css`
  color: ${error ? 'hsl(var(--color-warning-6))' : 'hsl(var(--color-text-1))'};
  font-family: Neue Machina;
  font-weight: bold;
  font-size: calc(var(--unit) * 8);
  line-height: calc(var(--unit) * 10);
  margin-top: calc(var(--unit) * 2);
`;

export const description = css`
  font-family: Aeonik;
  font-weight: normal;
  font-size: calc(var(--unit) * 6);
  line-height: calc(var(--unit) * 8);
  margin-top: calc(var(--unit) * 5);
`;

export const descriptionLink = css`
  color: hsl(var(--color-text-1));
  font-weight: 700;
  text-decoration: underline;
`;

export const btn = css`
  font-family: Aeonik;
  font-weight: normal;
  font-size: calc(var(--unit) * 5);
  line-height: calc(var(--unit) * 8);
  text-decoration: underline;
  margin-top: calc(var(--unit) * 5);
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

import Seo from 'modules/app/components/Seo';
import { SupportPage } from 'modules/support';
import React from 'react';
import { CookieConsent, PublicLayout } from '../modules/app';
import useSiteMetadata from 'modules/app/graphql/useSiteMetadata';

const FAQ: React.FC<WebPageProps> = ({ location, pageContext }) => {
  const { hash } = location;
  const { siteUrl } = useSiteMetadata();
  const { intl } = pageContext;
  const path = `${siteUrl}/${intl.language}${intl.originalPath}`;

  return (
    <>
      <Seo
        url={path}
        title="Get Support - Roango"
        description="Help center for Roango platform. Browse support information, FAQs, and guides, or contact our customer service team."
        image={`${siteUrl}/roango-share-image.png`}
      />

      <PublicLayout>
        <SupportPage categoryHash={hash} />
      </PublicLayout>

      <CookieConsent />
    </>
  );
};

export default FAQ;

import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';

export const contentWrapper = css`
  grid-column: 1 / span 12;
  margin-top: calc(var(--unit) * 15);

  @media ${breakpoints.medium} {
    margin-top: calc(var(--unit) * 20);
  }

  @media ${breakpoints.large} {
    grid-column: 5 / span 7;
    margin-top: 0;
  }
`;

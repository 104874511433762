import { Heading } from 'modules/app';
import React, { useState } from 'react';
import { motion } from 'framer-motion';

import IconCaretBottom from 'assets/icons/closePreview.svg';

import * as accordion from '../styles/Accordion.styles';
import { RichText } from 'prismic-reactjs';

interface Props {
  faq: FaqItem;
  id: number;
}

const animationAccordion = {
  closed: { height: 0 },
  open: { height: 'auto' },
};

const animationCaret = {
  closed: { rotate: 0 },
  open: { rotate: 180 },
};

export const Accordion: React.FC<Props> = ({ faq, id }) => {
  const question = faq.data.question.text;
  const answer = faq.data.answer.raw;

  const [open, setOpen] = useState(false);

  // Handle activating the Accordion component using Enter or Space keys
  const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      e.stopPropagation();

      setOpen(!open);
    }
  };

  return (
    <motion.article
      tabIndex={1}
      css={accordion.accordion}
      onKeyDown={handleKeyPress}
      transition={{
        ease: [0.77, 0.0, 0.175, 1.0],
        staggerChildren: 0.2,
      }}
    >
      <motion.div
        css={accordion.border}
        variants={{ hidden: { width: 0 }, visible: { width: '100%' } }}
        transition={{
          duration: 0.6,
          ease: [0.77, 0.0, 0.175, 1.0],
          type: 'keyframes',
        }}
      />
      <motion.header
        variants={{
          hidden: { x: -20, opacity: 0 },
          visible: { x: 0, opacity: 1 },
        }}
        transition={{ duration: 0.4, ease: 'easeIn' }}
        tabIndex={1}
        css={accordion.header}
        id={`faq-${id}-header`}
        aria-controls={`faq-${id}-panel`}
        aria-expanded={open}
        onClick={() => setOpen(!open)}
      >
        <Heading as="h2" size="fluidLarge" css={accordion.title}>
          {question}
        </Heading>

        <motion.i
          animate={open ? 'open' : 'closed'}
          variants={animationCaret}
          transition={{ duration: 0.2, ease: 'easeInOut', type: 'keyframes' }}
        >
          <IconCaretBottom />
        </motion.i>
      </motion.header>

      <motion.section
        initial="closed"
        animate={open ? 'open' : 'closed'}
        variants={animationAccordion}
        transition={{
          duration: 0.4,
          type: 'keyframes',
          ease: 'easeInOut',
        }}
        css={accordion.body}
        aria-hidden={!open}
        aria-labelledby={`faq-${id}-header`}
        id={`faq-${id}-panel`}
      >
        <RichText render={answer} />
      </motion.section>
    </motion.article>
  );
};

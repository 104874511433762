import { text } from '@prototyp/gatsby-plugin-gumball/utils';
import { Link } from 'gatsby-plugin-intl';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { motion } from 'framer-motion';
import { useOnScrollAnimation } from 'modules/app';

import * as styles from '../styles/SideNav.styles';

interface Props {
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  isCategoryActive: (categoryText: string) => boolean;
  category: string;
}

export const SideNav: React.FC<Props> = ({ isCategoryActive }) => {
  const root = useOnScrollAnimation({ threshold: 0 });
  const VARIANT = {
    visible: {
      transition: {
        staggerChildren: 0.3,
        delayChildren: 1,
      },
    },
  };

  const query = useStaticQuery(graphql`
    query faqCategories {
      allPrismicCategory(
        sort: { fields: data___order_number, order: ASC }
        filter: {
          data: { visbile: { eq: true } }
          tags: { eq: "faq category" }
        }
      ) {
        nodes {
          data {
            visbile
            order_number
            category {
              text
            }
          }
          prismicId
        }
      }
    }
  `);

  const faqCategories = query.allPrismicCategory.nodes;

  return (
    <motion.aside
      ref={root.ref}
      variants={VARIANT}
      initial="hidden"
      animate={root.animation}
      transition={{ delay: 1 }}
      css={styles.sideNavWrapper}
    >
      {faqCategories.map((categoryItem: FaqCategoryItem, index: number) => {
        const categoryName = categoryItem.data.category.text;

        return (
          <motion.div
            variants={{
              hidden: { x: -10, opacity: 0 },
              visible: { x: 0, opacity: 1 },
            }}
            transition={{ duration: 0.3, ease: 'easeIn' }}
            key={index}
            css={styles.buttonWrapper}
          >
            <Link
              to={`/support#${categoryName.toLowerCase().split(' ').join('-')}`}
              css={[
                styles.navButton,
                isCategoryActive(categoryName)
                  ? text.color.text
                  : text.color.borderDark,
              ]}
            >
              <span>
                <strong>{categoryName}</strong>
              </span>
              <motion.div
                initial={{ width: 0 }}
                animate={
                  isCategoryActive(categoryName) ? { width: 48 } : { width: 0 }
                }
                css={styles.line}
                transition={{ duration: 0.4, ease: 'easeInOut' }}
              />
            </Link>
          </motion.div>
        );
      })}
    </motion.aside>
  );
};

import React from 'react';
import { Accordion } from './Accordion';

import * as styles from 'modules/support/styles/FaqContent.styles';
import { motion } from 'framer-motion';
import { useOnScrollAnimation } from 'modules/app';

interface Props {
  filteredDocuments: (documents: FaqItem[]) => FaqItem[];
  documents: FaqItem[];
  isFirstRender: boolean;
}

export const FaqContent: React.FC<Props> = ({
  filteredDocuments,
  documents,
  isFirstRender,
}) => {
  const root = useOnScrollAnimation({ threshold: 0 });
  const VARIANT = {
    visible: {
      transition: {
        staggerChildren: 0.1,
        delayChildren: isFirstRender ? 1 : 0,
      },
    },
    exit: {
      x: 100,
      opacity: 0,
      transition: {
        duration: 0.4,
      },
    },
  };

  const questions = filteredDocuments(documents);

  return (
    <motion.article
      ref={root.ref}
      css={styles.contentWrapper}
      initial="hidden"
      animate={root.animation}
      variants={VARIANT}
      exit="exit"
    >
      {questions.map((item: FaqItem, index: number) => (
        <Accordion faq={item} key={`faq-${index}`} id={index} />
      ))}
    </motion.article>
  );
};

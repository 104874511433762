import { css } from '@emotion/react';

const maxWidth = '528px';

export const accordion = css`
  margin-bottom: var(--spacing-xhuge);
`;

export const header = css`
  margin-top: var(--spacing-xlrg);
  margin-bottom: var(--spacing-xlrg);
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
`;

export const title = css`
  max-width: ${maxWidth};

  font-family: var(--fon-family-primary);
  color: hsl(var(--color-text-1));
`;

export const body = css`
  overflow: hidden;
  max-width: ${maxWidth};
  color: hsl(var(--color-borderDark-5));

  p {
    margin-bottom: 1rem;
  }

  a {
    color: hsl(var(--color-text-1));
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const text = css`
  max-width: ${maxWidth};
  color: hsl(var(--color-borderDark-5));
`;

export const border = css`
  height: 1px;
  background: hsl(var(--color-grayscale-2));
`;

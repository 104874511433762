import { FormattedMessage } from 'gatsby-plugin-intl';
import { Heading, Paragraph } from 'modules/app';
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import CheckIcon from 'assets/icons/checkDark.svg';
import ErrorIcon from 'assets/icons/closeOutlineRed.svg';

import * as submitMsg from '../styles/OnSubmitMessage.styles';

interface Props {
  heading: string;
  description: string;
  error?: boolean;
  handleClick: () => void;
}

const OnSubmitMessage: React.FC<Props> = ({
  heading,
  description,
  error,
  handleClick,
}) => {
  const intl = useIntl();

  return (
    <section css={submitMsg.wrapper}>
      <div>
        <div css={submitMsg.icon(error)}>
          {error ? <ErrorIcon /> : <CheckIcon />}
        </div>
      </div>
      <div>
        <Heading as="h3" size="fluidLarge" css={submitMsg.title(error)}>
          <FormattedMessage id={heading} />
        </Heading>
        <Paragraph size="fluidMedium" css={submitMsg.description}>
          <FormattedMessage
            id={description}
            values={{
              support_mail: (
                <a
                  css={submitMsg.descriptionLink}
                  href={`mailto:${intl.formatMessage({
                    id: 'support.form.support_mail',
                  })}`}
                >
                  <FormattedMessage id="support.form.support_mail" />
                </a>
              ),
            }}
          />
        </Paragraph>
        <button onClick={handleClick} css={submitMsg.btn}>
          <FormattedMessage id="support.form.back_to_form" />
        </button>
      </div>
    </section>
  );
};

export default OnSubmitMessage;

import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from 'style/variables';

export const sideNavWrapper = css`
  color: hsl(var(--color-borderDark-5));
  grid-column: 1 / span 12;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${breakpoints.large} {
    grid-column: 1 / span 3;
    padding: 0;
  }
`;

export const navButton = css`
  color: hsl(var(--color-borderDark-5));
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;

  &::after {
    height: 1px;
    background: black;
    width: 20px;
  }

  &:focus,
  &:hover {
    color: hsl(var(--color-text-1));
  }
`;

export const navButtonActive = css`
  ${navButton};
  color: hsl(var(--color-text-1));
`;

export const line = css`
  height: 1px;
  background: hsl(var(--color-text-1));
  margin-left: ${spacing(12)};
  max-width: ${spacing(48)};
`;

export const buttonWrapper = css`
  margin-bottom: ${spacing(12)};

  :last-of-type {
    margin-bottom: 0;
  }
`;

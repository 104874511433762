import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import { Navbar, Footer } from 'modules/app';
import { Header } from 'modules/support/components/Header';
import React, { useEffect, useRef } from 'react';
import { FaqContent, SideNav, SupportContent } from './components';
import { useFilters } from './hooks';

import * as styles from './styles/SupportPage.styles';

interface Props {
  categoryHash: string;
}

export const SupportPage: React.FC<Props> = ({ categoryHash }) => {
  const isFirstRender = useRef(true);
  const { category, setCategory, isCategoryActive, filteredDocuments } =
    useFilters();

  useEffect(() => {
    if (!categoryHash) {
      return;
    }

    const current = categoryHash.substring(1).split('-').join(' ');
    const faqCategory = current[0].toUpperCase() + current.substring(1);
    setCategory(faqCategory);
  }, [categoryHash, setCategory]);

  const query = useStaticQuery(
    graphql`
      query faq {
        allPrismicFaq(sort: { fields: data___order_number, order: ASC }) {
          nodes {
            data {
              category
              answer {
                raw
              }
              question {
                text
              }
              faqcategory {
                id
                document {
                  ... on PrismicCategory {
                    id
                    data {
                      category {
                        text
                      }
                    }
                  }
                }
              }
              order_number
            }
          }
        }
      }
    `,
  );

  const documents: FaqItem[] = query.allPrismicFaq.nodes;

  useEffect(() => {
    if (!isFirstRender.current) {
      return;
    }

    isFirstRender.current = false;
  }, []);

  return (
    <section css={styles.root}>
      <Navbar />
      <Header />

      <LayoutGroup>
        <motion.div layout="position" css={styles.wrapper}>
          <SideNav
            setCategory={setCategory}
            isCategoryActive={isCategoryActive}
            category={category}
          />

          <AnimatePresence exitBeforeEnter>
            <FaqContent
              key={category}
              filteredDocuments={filteredDocuments}
              documents={documents}
              isFirstRender={isFirstRender.current}
            />
          </AnimatePresence>
        </motion.div>

        <SupportContent />
      </LayoutGroup>
      <Footer />
    </section>
  );
};

import { motion } from 'framer-motion';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Heading, useOnScrollAnimation } from 'modules/app';
import * as styles from 'modules/support/styles/SupportContent.styles';
import React from 'react';
import {
  FADE_IN_MOVE_Y_CONTROLLED,
  STAGGER_CONTAINER,
} from 'style/animations.config';
import { Form } from './Form';

export const SupportContent: React.FC = () => {
  const root = useOnScrollAnimation();

  return (
    <motion.section
      ref={root.ref}
      initial="hidden"
      animate={root.animation}
      layout="position"
      css={styles.root}
      id="support-contact-form"
      variants={STAGGER_CONTAINER}
    >
      <motion.div
        css={styles.dividerWrapper}
        variants={{ hidden: { width: 0 }, visible: { width: '100%' } }}
        transition={{ duration: 1.5, ease: [0.77, 0.0, 0.175, 1.0] }}
      />
      <Heading
        as="h2"
        size="fluidXLarge"
        css={styles.headingSecondary}
        useMotion
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        transition={{ duration: 0.5, ease: 'easeInOut', type: 'keyframes' }}
      >
        <FormattedMessage id="support.reach_out" />
      </Heading>
      <motion.div
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        css={styles.secondaryDescription}
        transition={{ duration: 0.5, ease: 'easeInOut', type: 'keyframes' }}
      >
        <p>
          <FormattedMessage id="support.feedback_description" />
        </p>
      </motion.div>
      <Form />
    </motion.section>
  );
};

import { motion } from 'framer-motion';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Heading, useOnScrollAnimation } from 'modules/app';
import * as styles from 'modules/support/styles/Header.styles';
import React from 'react';
import { FADE_IN_MOVE_Y_CONTROLLED, TRANSITION } from 'style/animations.config';

export const Header: React.FC = () => {
  const root = useOnScrollAnimation();

  const VARIANT = {
    visible: {
      transition: { staggerChildren: 0.3, delayChildren: 0.5 },
    },
  };

  return (
    <motion.section
      initial="hidden"
      animate={root.animation}
      variants={VARIANT}
      ref={root.ref}
      css={styles.wrapper}
    >
      <Heading
        as="h1"
        size="fluidXHuge"
        css={styles.title}
        useMotion
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        transition={TRANSITION()}
      >
        <FormattedMessage id="support.title" />
      </Heading>

      <motion.div
        css={styles.divider}
        variants={{ hidden: { width: 0 }, visible: { width: '100%' } }}
        transition={{ duration: 1.5, ease: [0.77, 0.0, 0.175, 1.0] }}
      />

      <Heading
        as="h2"
        size="fluidXLarge"
        css={styles.titleSecondary}
        useMotion
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
      >
        <FormattedMessage id="support.faq" />
      </Heading>
    </motion.section>
  );
};

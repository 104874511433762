import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';

export const root = css`
  min-height: 100vh;
`;

export const wrapper = css`
  max-width: 1312px;
  margin: 0 auto;
  padding-left: var(--spacing-xlrg);
  padding-right: var(--spacing-xlrg);
  box-sizing: content-box;

  @media ${breakpoints.medium} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: calc(var(--unit) * 10);
    margin-top: calc(var(--unit) * 10);
  }
`;

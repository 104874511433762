import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';

export const useFormSubmit = () => {
  const http = useHttpClient('/api/v1');

  async function onSubmit(info: SupportForm): Promise<HttpError | undefined> {
    const formData = new FormData();
    formData.append('email', info.email);
    formData.append('name', info.name || '');
    formData.append('question_categories', info.question_categories);
    formData.append('description', info.description);
    formData.append('image', info.image);

    try {
      await http.post<FormData>('/ticket', formData);
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    }
  }

  return { onSubmit };
};

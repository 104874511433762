import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import {
  Button,
  ImageInput,
  InputField,
  SelectComponent,
  TextArea,
  emailRegex,
} from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import * as styles from 'modules/support/styles/SupportContent.styles';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useFilters } from '../hooks';
import { useFormSubmit } from '../hooks/useFormSubmit';
import { faqAction, useFaqData } from '../redux';
import OnSubmitMessage from './OnSubmitMessage';

export const Form: React.FC = () => {
  const { getCategories } = useFilters();
  const dispatch = useDispatch();
  const { data } = useFaqData();
  const methods = useForm({
    defaultValues: {
      email: '',
      image: '',
      name: '',
      category: '',
      description: '',
    },
  });
  const { handleSubmit } = methods;
  const { onSubmit } = useFormSubmit();
  const [nextStep, setNextStep] = useState<number>(0);
  const { formatMessage } = useIntl();
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  function onSecondSubmit(formData: SupportForm) {
    setIsLoading(true);
    onSubmit({ ...formData, name: data.name, email: data.email }).then(
      (res) => {
        if (res instanceof HttpError) {
          setError(true);
          setIsLoading(false);
          return;
        }

        dispatch(faqAction.reset());
        setNextStep(2);
        setIsLoading(false);
      },
    );
  }

  const handleBackToForm = () => {
    setError(false);
    setNextStep(0);
  };

  if (error && nextStep > 0) {
    return (
      <OnSubmitMessage
        heading="support.form.error_title"
        description="support.form.error_description"
        error={error}
        handleClick={handleBackToForm}
      />
    );
  }

  if (nextStep === 2) {
    return (
      <OnSubmitMessage
        heading="support.form.success_title"
        description="support.form.success_description"
        error={error}
        handleClick={handleBackToForm}
      />
    );
  }

  return (
    <section css={styles.formWrapper}>
      <FormProvider {...methods}>
        {nextStep === 0 ? (
          <form
            onSubmit={handleSubmit(
              (formData: { name?: string; email: string }) => {
                dispatch(faqAction.saveData(formData));
                setNextStep(1);
              },
            )}
          >
            <InputField
              name="name"
              label="support.form.name"
              value=""
              constraints={{ required: false }}
            />

            <InputField
              name="email"
              label="support.form.email"
              value=""
              constraints={{
                required: {
                  value: true,
                  message: 'register.errors.email_required',
                },
                pattern: {
                  value: emailRegex,
                  message: 'register.errors.email_invalid',
                },
              }}
            />

            <Button
              type="submit"
              buttonType="secondary"
              css={styles.formSubmit}
            >
              <FormattedMessage id="support.form.next_step" />
            </Button>
          </form>
        ) : (
          <form
            onSubmit={handleSubmit((formData: SupportForm) =>
              onSecondSubmit(formData),
            )}
          >
            <SelectComponent
              name="question_categories"
              values={getCategories()}
              label={<FormattedMessage id="support.form.question_categories" />}
              constraints={{
                required: {
                  value: true,
                  message: formatMessage({
                    id: 'support.form.question_categories_required',
                  }),
                },
              }}
              placeholder={formatMessage({
                id: 'support.form.select_category',
              })}
            />

            <TextArea
              value=""
              constraints={{
                maxLength: {
                  value: 500,
                  message: 'shared.errors.max',
                },
                required: {
                  value: true,
                  message: 'support.form.text_area.required',
                },
              }}
              maxChar="500"
              name="description"
              topLabel="support.form.text_area.label"
              placeholder="support.form.text_area.placeholder"
            />

            <ImageInput
              name="image"
              acceptedFiles={[
                'image/jpeg',
                'image/jpg',
                'image/png',
                'image/webp',
              ]}
              maxSize={10485760}
            />
            <Button
              type="submit"
              buttonType="secondary"
              css={styles.formSubmit}
              disabled={isLoading}
            >
              <FormattedMessage id="support.form.submit" />
            </Button>
          </form>
        )}
      </FormProvider>
    </section>
  );
};

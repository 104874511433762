import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import { breakpoints } from 'style/variables';

export const wrapper = css`
  max-width: 1312px;
  margin: 0 auto;
  box-sizing: content-box;
  padding: ${spacing(60)} ${spacing(20)} ${spacing(40)};

  @media ${breakpoints.medium} {
    padding-top: ${spacing(160)};
  }
`;

export const title = css`
  ${font.size.xxLarge};
  letter-spacing: -1px;

  @media ${breakpoints.medium} {
    ${font.size.xHuge};
  }
`;

export const divider = css`
  min-height: 2px;
  background: hsl(var(--color-text-1));
  margin: ${spacing(60)} 0 ${spacing(20)};

  @media ${breakpoints.medium} {
    margin: ${spacing(120)} 0 ${spacing(40)};
  }
`;

export const titleSecondary = css`
  ${font.size.large};

  @media ${breakpoints.medium} {
    ${font.size.xxLarge};
    max-width: ${spacing(416)};
  }
`;

import { useIntl } from 'gatsby-plugin-intl';
import { useState } from 'react';

export const useFilters = () => {
  const [category, setCategory] = useState<string>('General');
  const { formatMessage } = useIntl();

  const filteredDocuments = (documents: FaqItem[]) => {
    return documents.filter(
      (item) =>
        item.data?.faqcategory?.document?.data?.category.text === category,
    );
  };

  function isCategoryActive(categoryText: string) {
    return categoryText === category;
  }

  function getCategories() {
    const availableCategories = [
      'General',
      'Talent account',
      'Stealth mode',
      'My invitations and openings',
      'Company account',
      'Creating opening',
      'Opening management',
      'Applications management',
      'Pipeline settings',
      'Pricing',
    ];

    const categories = availableCategories.map((category, i) => ({
      value: category,
      label: formatMessage({ id: `support.form.category_${i}` }),
    }));

    return categories;
  }

  return {
    setCategory,
    category,
    filteredDocuments,
    isCategoryActive,
    getCategories,
  };
};
